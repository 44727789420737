<template>
  <default-layout>
    <v-container id="registration-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <detail :detail="detail" :permissions="permissions"></detail>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/project-inquiries/detail');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.projectInquiry.permissions,
      detail: (state) => state.projectInquiry.detail,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.$store.commit('projectInquiry/RESET_DEFAULT');
      const response = await this.$store.dispatch(
        'projectInquiry/getDetailInitData',
        this.$route.params.id,
      );
      console.log('DETAIL PROJECT INQUIRIES: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
